table {
	border: 1px solid #ccc;
	width: 100%;
	margin: 0;
	padding: 0;
	border-collapse: collapse;
	border-spacing: 0;
	font-family: Source Sans Pro, sans-serif;
}

table tr {
	border: 1px solid #ddd;
	padding: 5px;
}

table tr:nth-child(even) {
	background-color: #ddd;
}

table td, table th {
	padding: 10px;
	text-align: left;
}


.text-right{
	text-align: right;
	padding-right: 40px;
}

table th {
	text-transform: uppercase;
	font-family: 18px;
	letter-spacing: 1px;
}

/*//////////////////////////////////////////////*/

@media screen and (max-width: 480px) {
	table {
		border:0;
	}

	table tr {
		margin-bottom: 10px;
		display: block;
		border-bottom: 1px solid #ddd;
		text-align: left;
	}

	table td {
		display: block;
		font-size: 16px;
		border-bottom: 1px solid #ccc;
		text-align: left;
	}

	table td:last-child {
		border-bottom: 0;
	}

	table td:before {
		content: attr(data-label);
		float: left;
		text-transform: uppercase;
		font-weight: 600;
	}

	table thead {
		display: none;
	}
}
