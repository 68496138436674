
* {
	 box-sizing: border-box;
}

html {
	 height: 100%;
	 width: 100%;
}

.msg{
	display: none;
}

span.error{
     font-size: 13px;
     margin-top: 5px;
     display: block;
}

body {
	font-family: $primary-font;
	color: #FFF;
	width: 100%;
	background: #333;
}

p{
    font-weight: 100;
    font-size: 18px;
    line-height: normal;
    color: #fff;
}
a{
	text-decoration: none;
}


h1, h2, h3, h4{
	 text-transform: uppercase;
	 font-weight: 100;
	 font-family: $primary-font; 
}


strong{
	font-weight: 500;
}

.container{
	 display: block;
	 margin: 0 auto;
	 position: relative;
	 overflow: hidden;
	 width: 90%;
	 max-width: 1100px;

	 @media (max-width: 900px){
		width: 95%;
	}
	 
}


main{
	padding-top: 0;
	min-height: 300px;

	@media (max-width: 860px){
		padding-top: 60px;
	}
}

.content-middle{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	-webkit-transform: translateY(-50%) translateX(-50%);
	-moz-transform: translateY(-50%) translateX(-50%);
	-ms-transform: translateY(-50%) translateX(-50%);
	-o-transform: translateY(-50%) translateX(-50%);
	width: 100%;
}


.img-responsive{
	display: block;
	width: 100%;
	height: auto;
}


.bg-white{
	border: #fff;
}

.bg-grey{
	background-color: #f4f4f4;
}

.section-sub-banner {
	padding: 45px 0;
	position: relative;

	.awe-overlay {
		position: absolute;
		height: 100%;
		top: 0;
		width: 100%;
		left: 0;
		background-color: rgba(72,72,72,.3);
		z-index: 0;
	}

	.sub-banner {
		position: relative;
	}

	.text {
		padding-bottom: 50px;
		text-align: left;

		h2, p{
			color: #fff;
		}

		h2{
			padding-top: 125px;
			font-size: 40px;
		}

		p{
			letter-spacing: 2px;
			margin-top: 10px;
			font-family: $second-font;
		}
	}
}


.bg-17 {
	background-image: url(../img/img-17.jpg);
	background-position: center top;
	background-attachment: fixed;
}

.bg-18 {
	background-image: url(../img/img-18.jpg);
	background-position: center top;
	background-attachment: fixed;
}

.bg-19 {
	background-image: url(../img/img-19.jpg);
	background-position: center top;
	background-attachment: fixed;
}


.bg-20 {
	background-image: url(../img/img-20.jpg);
	background-position: center top;
	background-attachment: fixed;
}


.bg-21 {
	background-image: url(../img/img-21.jpg);
	background-position: center top;
	background-attachment: fixed;
}

.bg-22 {
	background-image: url(../img/img-22.jpg);
	background-position: center top;
	background-attachment: fixed;
}

.bg-23 {
	background-image: url(../img/img-23.jpg);
	background-position: center top;
	background-attachment: fixed;
	padding: 10% 0;
	.text{
		text-align: left;
		color: #fff;
		margin-top: 10%;
		@media (max-width: 768px){
			text-align: center;
		}
		h1{
			font-family: $primary-font;
			font-size: 60px;
			text-transform: inherit;
			font-weight: 100;
			max-width: 700px;
			margin-bottom: 20px;
			display: block;

			@media (max-width: 680px){
				font-size: 55px;
			}
			span{
				font-family: $primary-font;
				font-weight: 100;
				text-transform: initial;
				font-size: 30px;
				line-height: 35px;
				display: block;
				@media (max-width: 680px){
					font-size: 20px;
				}
			}
		}
		
	}
}








///// SOMOS //////


.item_profile{
	display: block;
	overflow: hidden;
	height: 400px;
	margin: 30px 0;
	background: #444;

	@media (max-width: 950px){
		height: auto;	}
	

	.item__photo{
		width: 30%;
		height: 400px;
		display: block;
		float: left;
		margin-right: 2%;
		position: relative;

		@media (max-width: 950px){
			width: 100%;
			height: 350px;
			background: url(../img/bg-profile.jpg) center center no-repeat;
		}

		

		.box-photo-profile{
			display: block;
			position: relative;


			@media (max-width: 950px){
				position: absolute;
				width: 200px;
				height: 200px;
				overflow: hidden;

				
				top: 50%;
				left: 50%;
				border-radius: 100%;

				transform: translateX(-50%) translateY(-50%);
				-webkit-transform: translateX(-50%) translateY(-50%);
			}
			img{
				width: 100%;
				height: auto;
			}
		}

		.box-name{
			position: absolute;
			bottom: 2%;
			padding: 10px;
			
			@media (max-width: 950px){
				text-align: center;
				width: 100%;
				bottom: 0;
			}

			h3, h4{
				color: #fff;
				font-size: 20px;
				text-transform: inherit;
				font-weight: 700;
				margin-bottom: 10px;
			}

			h4{
				font-size: 15px;
			}
		}
	}

	.item__bio{
		width: 68%;
		display: block;
		float: left;
		height: 400px;
		position: relative;

		@media (max-width: 950px){
			width: 100%;
			height: auto;
		}

		.description{
			margin: 10% auto 2% auto;
			padding: 30px;
			position: relative;
			background: rgba(238, 238, 238, 0.3);
			border-left: 5px solid $primary-color;
               border-radius: 5px;
               width: 90%;
               overflow: hidden;
			
			h3, h4{
				font-size: 25px;
				font-style: italic;
				font-weight: 700;
				text-transform: inherit;
				color: #222;
				margin-bottom: 10px;
			}

			h4{
				font-size: 15px;
				margin: 10px auto;
			}

			p{
				color: #f4f4f4;
				line-height: 15px;
                    font-size: 15px;
				font-family: $second-font;
				font-style: italic;
                    margin-bottom: 20px;
			}
		}
		.social{
		    display: block;
		    bottom: 25px;
		    z-index: 4;
		    width: auto;
		    right: 45px;

		    @media (max-width: 950px){
				
				position: relative;
				width: 90%;
				margin: 20px auto;
				bottom: 0;
				right: 0;
			}
			
			p{
				line-height: 25px;
                    float: left;
                    margin-right: 30px;
                    margin-bottom: 0;
			}
			span{
				display: block;
				border: 1px solid #f4f4f4;
				float: left;
				width: 30px;
				height: 30px;
				text-align: center;
				line-height: 25px;
				border-radius: 50%;
				margin-right: 10px;
			}

		}
	}
}







/////PRODUCTOS////

.content-producto{
	display: block;
	width: 100%;
	background: #333;
	padding: 30px 0;

	.item_banner{
		display: block;

		img{
			width: 100%;
			display: block;
		}
	}
	


	.item_producto{
		display: block;
		margin-bottom: 30px;
		width: 100%;
		float: left;
		margin-right: 6%;
		padding: 10px;

		&:nth-child(2n){
			margin-right: 0;
		}

		@media (max-width: 680px){
			width: 100%;
		}

		h2{
		    font-family: $primary-font;
			font-size: 40px;
			text-transform: inherit;
			font-weight: 100;
			margin-top: 30px;
			margin-bottom: 30px;
		}

		h3{
		    font-family: $primary-font;
			font-size: 20px;
			text-transform: inherit;
			font-weight: 300;
			margin-top: 20px;
			margin-bottom: 10px;
		}

		.item__content{
			display: block;
			margin-bottom: 20px;
		

			h3{
				display: block;
				margin-bottom: 10px;
				text-transform: inherit;
				font-weight: 700;
			}

			p{
				margin-top: 20px;
				font-weight: 100;
				font-size: 18px;
				line-height: normal;
				color: #f4f4f4;
				
			}
		}
	}
}


//////////////////LANGING////////////////

.content-landing{
	display: block;
	height: auto;
	overflow: hidden;
	min-height: 300px;
	padding: 60px 0;
	background: #fff;

	.item_landing{
		display: block;
		width: 49%;
		float: left;
		margin-right: 2%;
		margin-bottom: 2%;
		color: #181818;

		&:nth-child(2n){
			margin-right: 0;
		}

		@media (max-width: 768px){
			width: 100%;
			margin-right: 0;
		}

		h2{
			font-family: $primary-font;
			font-size: 40px;
			text-transform: inherit;
			font-weight: 100;
			margin-bottom: 60px;
			
		}

		h3{
			font-weight: 700;
			letter-spacing: 1px;
		}
		p{
			margin-top: 40px;
			font-weight: 100;
			font-size: 18px;
			line-height: normal;
			color: #666;
		}

		.content-form{
			display: block;
			padding: 0 40px;
		}
		img{
			display: block;
			width: 100%;
			height: auto;
		}
	}
}

.content-form-landing {
	background-image: url(../img/img-24.jpg);
	background-position: center top;
	background-attachment: fixed;
	min-height: 400px;
	position: relative;
	padding: 70px 0;

	&:after{
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background:rgba(0, 0, 0, .5);
		top: 0;
		z-index: 0;
	}
	.item__form{
		display: block;
		z-index: 2;
		position: relative;
		color: #fff;

		

		h2{
			font-family: $primary-font;
			font-size: 40px;
			text-transform: inherit;
			font-weight: 100;
		}

		h3{
			font-weight: 700;
			letter-spacing: 1px;
		}
		p{
			margin-top: 20px;
			font-weight: 100;
			font-size: 18px;
			line-height: normal;
			color: #fff;
		}

		ul{
			display: block;
			overflow: hidden;
			margin-top: 40px;
			height: auto;
			li{
				line-height: 30px;
				font-size: 18px;

				i{
					margin-right: 10px;
				}
			}
		}
	}
}






.box-form{
	display: block;
	width: 49%;
	float: left;
	margin-right: 2%;
	margin-bottom: 2%;

	&:nth-child(2n){
		margin-right: 0;
	}

	@media (max-width: 768px){
		width: 100%;
		margin-right: 0;
	}

	form{
		display: block;

		.input-form{
			display: block;
			height: auto;

			input, textarea {
				display: block;
				width: 100%;
				height: 40px;
				box-sizing: border-box;
				outline: none;
				border: 1px solid #333;
				padding-left: 10px;
				background: rgba(0, 0, 0, .6);
				color: #fff;
			}

			textarea{
				height: 150px;
			}
		}
	}
}	














