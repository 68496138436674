header{
    display: block;
    overflow: hidden;
    width: 100%;
    z-index: 6;
    background: transparent;
    position: fixed;
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    border-bottom: 1px solid rgba(255,255,255 , .3);
    top: 0;

    @media (max-width: 860px){
        display: none;
    }
    
    nav{
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        font-size: 14px;
        float: right;
        
        ul.nav-desktop{
            list-style: none;
            margin: 0;
            padding: 0;
            
            li{
                float: left;
                position: relative;

                &:after{
                    content: "";
                    width: 0;
                    display: block;
                    background: $primary-color;
                    height: 1px;
                    position: absolute;
                    top: 80%;
                    left: 50%;

                    transform: translateX(-50%);
                    -webkit-transform: translateX(-50%);

                    transition: all .3s;
                    -webkit-transition: all .3s;
                }
                
                a{
                    display: block;
                    padding: 0 30px;
                    line-height: 70px;
                    font-family: Source Sans Pro, sans-serif;
                    color: #fff;
                    font-weight: 300;
                    text-transform: uppercase;
                    font-size: 13px;

                    -webkit-transition: all .2s ease;
                    -moz-transition: all .2s ease;
                    -ms-transition: all .2s ease;
                    -o-transition: all .2s ease;
                }
                
                .current{
                    &:after{
                        content: "";
                        width: 100%;
                        display: block;
                        background: $primary-color;
                        height: 1px;
                        position: absolute;
                        top: 80%;
                    }
                }

                &:hover{
                    &:after{
                        width: 70%;
                    }
                }
                    
                   
            }
        }
    }

    .logo{
        display: inline-block;
        margin-top: 15px;
        
        img{
            display: block;
            width: 170px;
        }

    }
}


.header-mobile{
    display: none;
    width: 100%;
    z-index: 10;
    height: 60px;
    background: #333;
    position: fixed;
    border-bottom: 1px solid #666;
    
    @media (max-width: 860px){
         display: block;
      }

      .logo{
            display: inline-block;
            margin-top: 13px;
                img{
                    display: block;
                    max-width: 180px;
                }
        }

        ul.nav-mobile{
            display: none;
            height: auto;
            left: 0;
            list-style: outside none none;
            position: absolute;
            text-align: center;
            top: 60px;
            width: 100%;

            li{
                background: #333;
                border-bottom: 1px solid #313131;
                height: 50px;
                line-height: 50px;
                transition: all .4s;
                -moz-transition: all .4s;
                -webkit-transition: all .4s;

                a{
                    color: #666;
                    display: block;
                    height: 100%;
                    text-transform: uppercase;
                    width: 100%;
                    font-size: 12px;

                    &:hover{
                        color: #fff;
                    }
                }

                &:hover{
                    background-color: $primary-color;
                }
            }
            .current{
                background: $primary-color;
                color: #fff;
            }
        }

    .down-menu {
        cursor: pointer;
        display: block;
        font-size: 25px;
        font-weight: 100;
        height: 60px;
        line-height: 60px;
        position: absolute;
        right: 0;
        text-align: center;
        text-transform: uppercase;
        width: 60px;
        color: #666;
    }    
}



.header-fixed{
  position: fixed;
  top: 0;
  background: #333;
  transition: all .5s;
  -moz-transition: all .5s;
  -webkit-transition: all .5s;
  border-bottom: 1px solid #444;

    nav{
        ul.nav-desktop{
            li{
                a{
                    color: #f4f4f4;
                    font-weight: 500;
                }
            }
        }
    }
}












