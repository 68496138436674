.banner{
	position: relative;
	background: #e9e9e9;
	margin-bottom: 40px;

	&:after {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 169px;
		background: url(../img/parallax-shadow.png) repeat-x;
		display: block;
		content: "";
		z-index: 1;
	}

	.container_cover{
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		right: 0;
		bottom: 0;
		opacity: 1;

		&:after{
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, .5);
			z-index: 1;
		}

		

	   .img_banner{
			display: block;
			width: 100%;
			height: auto;
			transition: opacity .3s cubic-bezier(.165,.84,.44,1);
			transform: translate3d(0,0,0);
			position: absolute;
			display: inline-block;

			@media (max-width: 1600px){
				 width: auto;
				 height: 100%;
			}
	    }
	}
	.content-middle{
		z-index: 2;
	}
}

.item{
	position: relative;
	height: 400px;
	color: #fff;

	@media only screen and (max-device-width: 1680px), only screen and (max-width: 1680px){
		 height: 466px;
		 padding-bottom: 0;
	}
	@media only screen and (max-device-width: 1024px) and (min-device-width: 768px) and (orientation: portrait), only screen and (max-width: 900px)
	 {
		padding-bottom: 53.33%;
	}

	.caption-full{
		position: absolute;
		top: 50%;
		-o-transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		width: 80%;
		left: 50%;

	}
}


.inner-1{
	display: block;

	h1{
		text-align: center;
		font-size: 50px;
		font-weight: 300;

		strong{
			font-size: 65px;
			display: block;
		}

		@media (max-width: 768px){
			font-size: 25px;

			strong{
				font-size: 35px;
			}
		}
	}
}



.ple_slider_button {
	margin-top: 24px;

	.btn-banner{
	  display: block;
	  border: 1px solid #fff;
	  color: #fff;
	  max-width: 200px;
	  margin: 0 auto;
	}
}






















