.productos{
	display: block;
	height: auto;
	overflow: hidden;
	min-height: 300px;
	text-align: center;
	margin-bottom: 40px;

	h2{
		font-size: 40px;
		font-weight: 100;
		color: #f4f4f4;
	}

	p{
		font-size: 18px;
		margin-top: 20px;
		color: #f4f4f4;
		line-height: normal;

	}
	
	.content-box{
		display: block;
		overflow: hidden;
		margin-top: 30px;

		.item_box{
			width: 24.25%;
			float: left;
			display: block;
			margin-right: 1%;
			margin-bottom: 1%;
			position: relative;
			box-sizing: border-box;

			&:nth-child(4n){
				margin-right: 0;
			}

			a, p{
				color: #fff;
				line-height: 15px;
			}

			a{
				background: rgba(0, 0, 0, .4);
			}

			.mask{
				display: block;
				position: absolute;
				top: 0;
				background: rgba(0, 0, 0, .4);
				width: 100%;
				height: 100%;

				transition: all .3s;
				-webkit-ttransition: all .3s;
			}

			&:hover .mask{
				background: rgba(0, 0, 0, .6);
			}

			@media (max-width: 1000px){
				width: 49%;

				&:nth-child(2n){
					margin-right: 0;
				}
				
				&:nth-child(4n){
					margin-right: 0;
				}
			}
			
			@media (max-width: 680px){
				width: 100%;
				margin-bottom: 2%;
			}

			


			.content-img{
				display: block;
				overflow: hidden;
				width: 100%;
				height: 180px;

				img{
					display: block;
					width: auto;
					height: 100%;

					@media (max-width: 1000px){
						width: 100%;
						height: auto;
					}
				}

				@media (max-width: 1000px){
					width: 100%;
					height: auto;
				}
			}

			.caption_box{
				position: absolute;
				bottom: 0;
				padding: 10px;
				text-align: left;
			}
		}
	}
}


.content-somos{
	display: block;
	height: auto;
	overflow: hidden;
	min-height: 300px;
	text-align: center;
	margin-bottom: 40px;

	h2{
		font-size: 40px;
		font-weight: 100;
		margin-top: 30px;
		color: #f4f4f4;
		font-family: $primary-font;  
	}

	p{
		font-size: 18px;
		margin-top: 20px;
		color: #f4f4f4;
		line-height: normal;
	}

	.content-img{
		display: block;
		height: auto;
		overflow: hidden;
		margin-top: 40px;

		img{
			display: block;
			width: 100%;
			height: auto;
		}
	}
}






























