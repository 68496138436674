.title{
	padding: 30px 0;
	display: block;

	h2 {
        color: $second-color;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 20px;
    }

    p{
        margin-top: 10px;
        color: $second-color;
        line-height: 1.428em;
        letter-spacing: .02em;
        font-weight: 300;
        font-size: 17px;
        font-family: Source Sans Pro, sans-serif;
    }
}


.valores{
    display: block;
    overflow: hidden;
    margin-bottom: $margin-bottom;

    h2 {
        color: $second-color;
        text-transform: uppercase;
        font-size: 20px;
        margin-bottom: $margin-bottom;
    }

}