.content-history{
	display: block;
	overflow: hidden;
	height: auto;
	margin: 30px 0;
	background: #333;
    
    .item_history{
        display: block;
        overflow: hidden;
        margin-bottom: 40px;
        
        p{
            margin-bottom: 40px;
        }
        
        h3{
            font-weight: 400;
            margin-bottom: 20px;
        }

        .grid {
            margin: 0 auto;
          }
        .item-grid-list {
            display: block;
            overflow: auto;
            
            li{
                margin-bottom: 20px;
                display: block;
                position: relative;
                height: auto;
                float: left;
                margin-right: 2%;
                box-sizing: border-box;
                padding: 5px;
                border: 1px solid #494949;
                text-align: center;
                
                p{
                    color: #999;

                    span{
                        width: 100%;
                        border: 0;
                        background: #0691d7;
                        display: block;
                        height: 40px;
                        text-align: center;
                        line-height: 40px;
                        color: #fff;
                        text-transform: uppercase;
                        font-size: 20px;
                        margin-top: 15px;
                        font-weight: 700;
                    }
                }
                
                img{
                    display: block;
                    width: 100%;
                    height: auto;
                    max-width: 150px;
                    margin: 0 auto 5px auto;
                }

                &:nth-child(4n){
                    margin-right: 0;
                }
            }

            .grid-sizer,
            .grid-item { 
                width: 23.5%; 
                @media (max-width: 760px){
                    width: 48%;
                }

                @media (max-width: 480px){
                    width: 100%;
                }
            }
        }
    }
    
    .box-img, .box-text{
        display: block;
        overflow: hidden;
        width: 48%;
        float: left;
        margin-right: 4%;
        box-sizing: border-box;
        
        @media (max-width: 768px){
            width: 100%;
        }
    }
    .box-text{
        margin-right: 0;
        p{
            margin-top: 10%;
            margin-bottom: 0;
        }
    }

}



.operaciones{
    display: block;
    width: 100%;
    height: 300px;
    margin: 40px auto;
    position: relative;
    overflow: hidden;

    &:after{
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .6);
    }


    img{
        position: absolute;
        top: 0;
        width: 100%;
        height: auto;

        @media (max-width: 960px){
            height: 100%;
            width: auto;
        }
    }

    h4{
        z-index: 4;
        display: block;
        color: #fff;
        position: absolute;
        text-align: center;
        width: 100%;
        top: 50%;
        font-size: 20px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);

        strong{
            display: block;
            border: 1px solid #fff;
            padding: 10px;
            margin: 20px auto;
            width: 150px;
        }
    }
}


.content-logos{
    display: block;
    background: #393939;
    padding-top: 30px;

    .banner-logo{
        position: relative;
        margin-bottom: 30px;
    
        .item{
            height: auto;
        }
    }
    
}


