.item_contacto-maps{
	z-index: 2;
	position: relative;
	h2 {
	    text-transform: uppercase;
	    font-weight: 700;
	    font-size: 30px;
	    display: block;
	}

	p{
		font-weight: normal;
	    font-size: 20px;
	    margin: 10px 0;
	    line-height: 14px;
	    font-family: Source Sans Pro, sans-serif;
	    letter-spacing: 1px;
	    font-weight: 300;
	    line-height: 22px;
	}
}

.container_ir{
	position: absolute;
	bottom: 100px;
	width: 100%;
	height: 50px;
	z-index: 2;

	.btn-map{
		background-color: $primary-color;
	    font-weight: 400;
	    text-align: center;
	    text-transform: uppercase;
	    font-size: 13px;
	    font-family: 'Source Sans Pro', sans-serif;
	    font-weight: 300;
	    line-height: 50px;
	    height: 50px;
	    border-radius: 0;
	    color: #fff;
	    display: inline-block;
	    vertical-align: middle;
	    box-shadow: 0 0 1px transparent;
	    position: relative;
	    -webkit-transition-duration: 0.3s;
	    transition-duration: 0.3s;
	    margin: 0 auto;
	    letter-spacing: 5px;
	    width: 80%;
	    max-width: 200px;
	    display: block;

	    &:hover{
	    	background-color: $primary-color + 30;
	    }
	}
}



.contacto{
	display: block;
		
	.text {
	    margin-top: 15px;
		text-align: center;
		width: 100%;


	    h2 {
		    color: #f4f4f4;
		    text-transform: uppercase;
		    font-weight: 700;
		    font-size: 20px;

		}

		p{
			margin-top: 10px;
		    line-height: 1.428em;
		    letter-spacing: .02em;
    		font-size: 17px;
    		font-family: $second-font;
    		font-weight: 300;
		}
	}

	.content-map{
		display: block;
		height: 400px;
		width: 100%;
		position: relative;
		margin-top: 40px;
	}

}


.item_contacto{
	display: flex;
	flex-wrap: wrap;
	padding: 40px 0;
}



.contact-form{
	display: block;
	width: 95%;
	max-width: 900px;
	margin: 40px auto 0;

	.input-form{
		display: block;
		height:  auto;
		margin-bottom: 7px;
		width: 100%;
		font-family: 'Source Sans Pro', sans-serif;


		input{
			width: 100%;
			height: 50px;
			border: 1px solid #ddd;
			background: #f7f7fa;
		}

		textarea{
			background: #f7f7fa;
		}
	}
}


.exito{
  margin-top: 200px; 
  text-align: center;
  display: block;
  height: auto;
  overflow: hidden;

     h2{
        font-weight: 400;
        font-size: 30px;
     }

     a{
      display: inline-block;
      white-space: nowrap;
      cursor: pointer;
      line-height: normal;
      padding: 8px 10px;
      font-size: 12px;
      min-width: 110px;
      border-width: 0;
      text-align: center;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -ms-transition: all .3s ease;
      -o-transition: all .3s ease;
      border: 2px solid $third-color;
      background-color: $third-color;
      color: #fff;
      text-transform: uppercase;
      margin: 20px auto;
      
        &:hover{
          background-color: $third-color - 30;
          border: 2px solid $third-color - 30;
       } 
     }

     
}



