$primary-color: #000;
$second-color: #181818;
$third-color: #15151e;



$margin-bottom: 20px;


$primary-font: 'Catamaran', sans-serif;
$second-font: 'Open Sans', sans-serif;
